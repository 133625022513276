import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Image, Modal, CardColumns, Button, Row, Col, Alert } from 'react-bootstrap'
import CONSTANTS from './constants/constants'
import { Estimate } from './models/estimate'
import Package from './package'
import './quote.css'
import PersonalInformationForm from './personalInfo'
import QuoteDetails from '../quoteDetails/quoteDetails'

type Pricing = {
    basicBoth?: number,
    basicExt?: number,
    driveway?: number,
    exteriorHouseWashing?: number,
    screenRepair?: number,
    screenBuilding?: number,
    blinds?: number
}

export type SelectedService = {
    service: string,
    price: number,
    amount?: number
}

const Quote = (props: { estimate: Estimate, setEstimate: any, setQuoteServices: any, nextPage: any, previousPage: any }) => {

    /** The pricing for each service that we offer */
    const [pricing, setPricing] = useState<Pricing>({})

    /** The main service that the user has selected */
    const [mainService, setMainService] = useState<SelectedService>()

    /** The list of services that the user has selected. Not including the main service. */
    const [services, setServices] = useState<SelectedService[]>([])

    /** The service object for screen repair */
    const [screenRepairService, setScreenRepairService] = useState<SelectedService>({
        service: CONSTANTS.SCREENS.REPAIR,
        price: 0
    })

    /** The service object for screen building */
    const [screenBuildingService, setScreenBuildingService] = useState<SelectedService>({
        service: CONSTANTS.SCREENS.BUILDING,
        price: 0
    })

    const [blindsService, setBlindsService] = useState<SelectedService>({
        service: CONSTANTS.BLINDS,
        price: 0
    })

    /** Whether or not to show the modal explaining to the user the dangers of going back */
    const [showPreviousModal, setShowPreviousModal] = useState(false)

    const [finished, setFinished] = useState(false)

    const [error, setError] = useState("")

    const [processing, setProcessing] = useState(false)

    const [noPackage, setNoPackage] = useState(false)

    const discount = props.estimate.Discount

    const updateMainService = (service: SelectedService) => {

        if (mainService?.service == service.service) {
            setMainService(undefined)
            return
        }

        setMainService(service)
    }

    const getHouseSize = () => {

        if (!props.estimate.GeneralInformation?.houseSize) { return }

        switch (props.estimate.GeneralInformation.houseSize) {
            case CONSTANTS.HOUSESIZE.SMALLEST:
                return 1800
            case CONSTANTS.HOUSESIZE.SMALLER:
                return 2000
            case CONSTANTS.HOUSESIZE.SMALL:
                return 2700
            case CONSTANTS.HOUSESIZE.MEDIUM:
                return 3300
            case CONSTANTS.HOUSESIZE.MEDIUMLARGE:
                return 3800
            case CONSTANTS.HOUSESIZE.LARGE:
                return 4200
            case CONSTANTS.HOUSESIZE.VERYLARGE:
                return 5000
            case CONSTANTS.HOUSESIZE.VERYVERYLARGE:
                return 6000
            case CONSTANTS.HOUSESIZE.HUGE:
                return 6000
            default:
                return 0
        }
    }

    const getHouseStories = () => {
        if (!props.estimate.GeneralInformation?.floorsAboveGround) { return }

        switch (props.estimate.GeneralInformation.floorsAboveGround) {
            case CONSTANTS.FLOORS.ONE_STORY:
                return 1
            case CONSTANTS.FLOORS.TWO_STORY:
                return 2
            case CONSTANTS.FLOORS.THREE_STORY:
                return 3
            default:
                return 0
        }
    }

    const roundNearest5 = (num: number) => {
        return Math.round(num / 5) * 5;
    }

    const getPrice = (isExtOnly: boolean) => {
        let houseStories = getHouseStories()
        const BASE_PRICE = 150
        const BASE_PRICE_TWO_STORIES = BASE_PRICE + 80

        if (!props.estimate.GeneralInformation?.houseSize) { return }

        // Get the exterior price
        let extPrice = 0
        if (houseStories == 1) {
            switch (props.estimate.GeneralInformation.houseSize) {
                case CONSTANTS.HOUSESIZE.SMALLEST:
                    extPrice = BASE_PRICE + 10
                    break
                case CONSTANTS.HOUSESIZE.SMALLER:
                    extPrice = BASE_PRICE + 25
                    break
                case CONSTANTS.HOUSESIZE.SMALL:
                    extPrice = BASE_PRICE + 50
                    break
                case CONSTANTS.HOUSESIZE.MEDIUM:
                    extPrice = BASE_PRICE + 75
                    break
                case CONSTANTS.HOUSESIZE.MEDIUMLARGE:
                    extPrice = BASE_PRICE + 100
                    break
                case CONSTANTS.HOUSESIZE.LARGE:
                    extPrice = BASE_PRICE + 150
                    break
                case CONSTANTS.HOUSESIZE.VERYLARGE:
                    extPrice = BASE_PRICE + 200
                    break
                case CONSTANTS.HOUSESIZE.VERYVERYLARGE:
                    extPrice = BASE_PRICE + 250
                    break
                default:
                    return 0
            }
        } else {
            switch (props.estimate.GeneralInformation.houseSize) {
                case CONSTANTS.HOUSESIZE.SMALLEST:
                    extPrice = BASE_PRICE_TWO_STORIES
                    break
                case CONSTANTS.HOUSESIZE.SMALLER:
                    extPrice = BASE_PRICE_TWO_STORIES + 25
                    break
                case CONSTANTS.HOUSESIZE.SMALL:
                    extPrice = BASE_PRICE_TWO_STORIES + 50
                    break
                case CONSTANTS.HOUSESIZE.MEDIUM:
                    extPrice = BASE_PRICE_TWO_STORIES + 75
                    break
                case CONSTANTS.HOUSESIZE.MEDIUMLARGE:
                    extPrice = BASE_PRICE_TWO_STORIES + 100
                    break
                case CONSTANTS.HOUSESIZE.LARGE:
                    extPrice = BASE_PRICE_TWO_STORIES + 150
                    break
                case CONSTANTS.HOUSESIZE.VERYLARGE:
                    extPrice = BASE_PRICE_TWO_STORIES + 250
                    break
                case CONSTANTS.HOUSESIZE.VERYVERYLARGE:
                    extPrice = BASE_PRICE_TWO_STORIES + 300
                    break
                default:
                    return 0
            }
        }

        // Reduce exterior price by 15%
        extPrice = extPrice * 0.85

        // Interior and exterior price is double the exterior price
        let bothPrice = extPrice * 2

        return isExtOnly ? extPrice : bothPrice
    }

    useEffect(() => {
        let windowCleaningBoth = (getPrice(false) ?? 0) / (1 - (props.estimate.Discount ?? 0))
        let windowCleaningExt = (getPrice(true) ?? 0) / (1 - (props.estimate.Discount ?? 0))

        if (!windowCleaningBoth) { return }
        if (!windowCleaningExt) { return }

        // Add solar screen pricing
        windowCleaningBoth = (windowCleaningBoth ?? 1) * (props.estimate.GeneralInformation?.solarScreens ? 1.5 : 1)
        windowCleaningExt = (windowCleaningExt ?? 1) * (props.estimate.GeneralInformation?.solarScreens ? 1.5 : 1)
        let myPricing = pricing
        myPricing.basicBoth = roundNearest5(windowCleaningBoth)
        myPricing.basicExt = roundNearest5(windowCleaningExt)
        myPricing.driveway = roundNearest5((getHouseSize() ?? 0) * .05)
        myPricing.exteriorHouseWashing = roundNearest5(((getHouseSize() ?? 0) * .2) * (getHouseStories() ?? 0))
        myPricing.blinds = roundNearest5(windowCleaningBoth * 0.3)

        setBlindsService({
            service: CONSTANTS.BLINDS,
            price: myPricing.blinds
        })

        setPricing((prevState) => ({
            ...prevState,
            basicBoth: windowCleaningBoth,
            basicExt: windowCleaningExt,
            driveway: myPricing.driveway,
            exteriorHouseWashing: myPricing.exteriorHouseWashing,
            blinds: myPricing.blinds
        }))

    }, [props.estimate])

    const screensToRepair = (numOfScreens: string) => {
        let myPricing = pricing

        myPricing.screenRepair = 25 * parseInt(numOfScreens)
        setPricing((prevState) => ({
            ...prevState,
            screenRepair: myPricing.screenRepair
        }))

        setScreenRepairService({
            service: CONSTANTS.SCREENS.REPAIR,
            price: myPricing.screenRepair,
            amount: parseInt(numOfScreens)
        })
    }

    const screensToBuild = (numOfScreens: string) => {
        let myPricing = pricing

        myPricing.screenBuilding = 50 * parseInt(numOfScreens)
        setPricing((prevState) => ({
            ...prevState,
            screenBuilding: myPricing.screenBuilding
        }))

        setScreenBuildingService({
            service: CONSTANTS.SCREENS.BUILDING,
            price: myPricing.screenBuilding,
            amount: parseInt(numOfScreens)
        })
    }

    const addService = (service: SelectedService) => {

        if (serviceSelected(service)) {
            removeService(service)
            return
        }

        setServices(services => [...services, service])
    }

    const removeService = (service: SelectedService) => {
        let myServices = [...services]
        let index = myServices.map(s => s.service).indexOf(service.service)
        myServices.splice(index, 1)
        setServices(myServices)
    }

    const serviceSelected = (service: SelectedService) => {
        let myServices = services

        for (let index = 0; index < services.length; index++) {
            const s = services[index];

            if (s.service == service.service) {
                return true
            }
        }

        return false
    }

    const AreYouSure = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowPreviousModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    If you go back you'll lose any information that you've entered on this page. Are you sure you want to go back?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={props.previousPage}><i className="fa-solid fa-check"></i> Yes</Button>
                    <Button variant="success" onClick={() => setShowPreviousModal(false)}><i className="fa-solid fa-times"></i> Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const NoPackageSelected = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setNoPackage(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Please Select A Service?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    In order to book you must select at least one service.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setNoPackage(false)}><i className="fa-solid fa-check"></i> Okay</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const handleSubmit = () => {
        if (!mainService && services.length == 0) {
            setNoPackage(true)
            return
        }

        setProcessing(true)

        let estimate: any = props.estimate
        estimate.mainService = mainService
        estimate.services = services
        props.setEstimate(estimate)
        props.setQuoteServices({
            mainService: mainService,
            services: services
        })
        props.nextPage()
    }

    return (
        <div>
            {
                !finished &&
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Your Quote</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="m-3">

                        {
                            pricing.basicExt != 0 &&
                            <div>
                                <h5 className="window-cleaning">Window Cleaning</h5>
                                <div className="info-text">
                                    <i><i className="fa-solid fa-circle-info me-2"></i>We know that you've done your best making sure that the information is as accurate as possible. But mistakes happen. Before we start on your home, we'll walk around to make sure that the information entered is accurate to a reasonable degree. If we find that the information entered is off by a large degree then we'll let you know the price before we begin.</i>
                                </div>
                                <Alert style={{ textAlign: "center" }} className="mt-3" variant="success"><h4>Book your service for before {moment().add(7, 'd').format('MMMM Do')} and get {(props.estimate.Discount ?? 1) * 100}% off</h4></Alert>
                                <hr />
                                <CardColumns className="mb-3">
                                    <Package
                                        discount={discount}
                                        header={CONSTANTS.WINDOWS.EXTERIOR}
                                        services={[<span>Windows cleaned <strong>outside only</strong></span>, "Screens removed and brushed and cleaned with soap and water", "$1 Million insurance protection", "Window tracks cleaned", "Frames and sills wiped down"]}
                                        buttonPressed={() => updateMainService(
                                            {
                                                service: CONSTANTS.WINDOWS.EXTERIOR,
                                                price: pricing.basicExt ?? 0
                                            })
                                        }
                                        price={pricing.basicExt ?? 0}
                                        selected={mainService?.service == CONSTANTS.WINDOWS.EXTERIOR}
                                        color="primary"
                                    ></Package>
                                    <Package
                                        discount={discount}
                                        header={CONSTANTS.WINDOWS.BOTH}
                                        services={[<span>Windows cleaned <strong>inside and outside</strong></span>, "Screens removed and brushed and cleaned with soap and water", "$1 Million insurance protection", "Window tracks cleaned", "Frames and sills wiped down"]}
                                        buttonPressed={() => updateMainService(
                                            {
                                                service: CONSTANTS.WINDOWS.BOTH,
                                                price: pricing.basicBoth ?? 0
                                            })
                                        }
                                        price={pricing.basicBoth ?? 0}
                                        selected={mainService?.service == CONSTANTS.WINDOWS.BOTH}
                                        color="primary"
                                    ></Package>
                                </CardColumns>

                            </div>

                        }


                        <h5 className="power-washing">Power Washing</h5>
                        <hr className="divider" />
                        <CardColumns>
                            <Package
                                discount={discount}
                                header={CONSTANTS.POWERWASH.DRIVEWAY}
                                services={["Using a power washer we remove any tire marks and dirt and grime from your driveway. "]}
                                buttonText="Add Service"

                                buttonPressed={() => {
                                    addService({
                                        service: CONSTANTS.POWERWASH.DRIVEWAY,
                                        price: pricing.driveway ?? 0
                                    })
                                }}
                                selected={
                                    serviceSelected({
                                        service: CONSTANTS.POWERWASH.DRIVEWAY,
                                        price: pricing.driveway ?? 0
                                    })
                                }

                                price={pricing.driveway ?? 0}
                                color="success" />
                            <Package
                                discount={discount}
                                header="Exterior House Washing"
                                services={["Using a low-pressure washer and specialized solutions we safely remove stains and gunk from the outside of your home."]}
                                buttonText="Add Service"
                                buttonPressed={() => {
                                    addService({
                                        service: CONSTANTS.POWERWASH.HOME,
                                        price: pricing.exteriorHouseWashing ?? 0
                                    })
                                }}
                                selected={
                                    serviceSelected({
                                        service: CONSTANTS.POWERWASH.HOME,
                                        price: pricing.exteriorHouseWashing ?? 0
                                    })
                                }
                                price={pricing.exteriorHouseWashing ?? 0}
                                color="success" />
                        </CardColumns>

                        <h5 className="screen-repair">Window Screen Repair and Rebuilding</h5>
                        <hr className="divider" />
                        <p><i><strong>If you need solar screens built or replaced please view our solar screens page for pricing. The section below is for normal bug screens. <a href="https://definedcleaning.com/solar-screens-las-vegas" target='_blank'>Click here to view our solar screens page.</a></strong></i></p>
                        <CardColumns>


                            {/* SCREEN REPAIR */}
                            <Package
                                discount={discount}
                                header="Screen Repair"
                                services={["Do you have any screens that are faded or torn that need to be repaired?", "Let us know how many screens need to be repaired and we can repair them at the appointment."]}
                                textFieldPlaceholder="Enter the amount of screens to repair"
                                textFieldUpdated={screensToRepair}
                                price={pricing.screenRepair}
                                buttonText="Add Service"

                                buttonPressed={() => {
                                    addService(screenRepairService)
                                }}
                                selected={
                                    serviceSelected(screenRepairService)
                                }

                                color="warning" />

                            {/* SCREEN BUILDING */}
                            <Package
                                discount={discount}
                                header="Screen Building"
                                services={["Do you have any windows that are missing screens? Do you have any window screen frames that are badly bent or broken?", "Let us know how many screens you need to be built and we can build them at the appointment."]}
                                textFieldPlaceholder="Enter the amount of screens to build"
                                textFieldUpdated={screensToBuild}
                                price={pricing.screenBuilding}
                                buttonText="Add Service"

                                buttonPressed={() => {
                                    addService(screenBuildingService)
                                }}
                                selected={
                                    serviceSelected(screenBuildingService)
                                }

                                color="warning" />
                        </CardColumns>

                        {
                            pricing.blinds != 0 &&
                            <div>
                                <h5 className="blind-cleaning">Blind Cleaning</h5>
                                <hr className="divider" />

                                <CardColumns>

                                    <Package
                                        discount={discount}
                                        header="Clean Blinds"
                                        services={["Are your windows covered by blinds? If so, you should get them cleaned regularly as well. Dirty blinds can detract from your clean windows."]}
                                        price={pricing.blinds}
                                        buttonText="Add Service"
                                        buttonPressed={() => {
                                            addService(blindsService)
                                        }}
                                        selected={
                                            serviceSelected(blindsService)
                                        }

                                        color="info" />
                                </CardColumns>
                            </div>
                        }
                        <hr />
                        {
                            noPackage && <NoPackageSelected />
                        }
                        <QuoteDetails discount={props.estimate.Discount} estimate={props.estimate} mainService={mainService} services={services} />
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="page-indicator mr-4">Page 3 of 4</span>
                        <Button variant="success" onClick={() => setShowPreviousModal(true)}><i className="fa-solid fa-arrow-left"></i> Previous</Button>
                        {
                            processing && <Image src="loading.gif" />
                        }
                        {
                            !processing && !finished && <Button onClick={() => handleSubmit()}>I'm Ready To Book</Button>
                        }

                    </Modal.Footer>
                </div>
            }

            {
                showPreviousModal && <AreYouSure />
            }
        </div >
    )
}

export { Quote }