import React, { useEffect } from 'react'
import { useState } from 'react'
import { Col, Button, Form, Modal, Spinner, Alert, Row } from 'react-bootstrap'
import { getEffectiveConstraintOfTypeParameter } from 'typescript'
import QuoteDetails from '../quoteDetails/quoteDetails'
import CONSTANTS from './constants/constants'
import { QuoteServices } from './estimator'
import { Estimate, Customer, Misc } from './models/estimate'
import './personalInfo.css'

const PersonalInformationForm = (props: { setEstimate: any, estimate: Estimate, quoteServices?: QuoteServices, previousPage: any }) => {

    const [validated, setValidated] = useState(false)

    let customer: Customer = props.estimate.Customer ?? {}

    let misc: Misc = props.estimate.Misc ?? {}

    const [finished, setFinished] = useState(false)

    const [error, setError] = useState("")

    const [processing, setProcessing] = useState(false)

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            sendQuote()
        }

        setValidated(true);
    };

    const sendQuote = () => {

        setProcessing(true)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(props.estimate)
        }

        fetch("https://defined-cleaning.herokuapp.com/sendMessage", requestOptions)
            .then(data => {
                console.log(data)
                setFinished(true)
                setProcessing(false)
            })
            .catch(error => {
                setError(error.message)
                setProcessing(false)
            })
    }

    const print = () => {
        window.print()
    }

    const getAddOnsPrice = () => {
        return props.quoteServices?.services.reduce((a, b) => a + b.price, 0)
    }

    const scheduleAppointment = () => {
        let totalPrice = roundNearest5(((getAddOnsPrice() ?? 0) + (props.quoteServices?.mainService?.price ?? 0)) * (1 - (props.estimate.Discount ?? 0)))

        if (totalPrice < 150) {
            // Job should take about 1 hour
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=16635721")
        } else if (totalPrice < 200) {
            // Job should take about 2 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=39980877")
        } else if (totalPrice < 300) {
            // Job should take about 3 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345092")
        } else if (totalPrice < 400) {
            // Job should take about 4 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345584")
        } else if (totalPrice < 500) {
            // Job should take about 5 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345587")
        } else {
            // Job should take about 6 hours
            window.location.replace("https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345591")
        }
    }

    const roundNearest5 = (num: number) => {
        return Math.round(num / 5) * 5;
    }

    return (
        <div>
            {
                finished &&
                <div>
                    <Modal.Header className="no-printme">
                        <Modal.Title className="ml-3">Thank You!</Modal.Title>
                        <Button className="ml-3 no-printme" onClick={print}>
                            Print Quote
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="m-3">
                        <Row>
                            <Col>
                                <p>Thank you so much for filling out the online form! Please click on 'Schedule Appointment' below so you can schedule a time and date for your appointment.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Button className="ml-3 no-printme" onClick={scheduleAppointment}>Schedule Appointment</Button>
                        </Row>
                        <QuoteDetails discount={props.estimate.Discount} estimate={props.estimate} mainService={props.quoteServices?.mainService} services={props.quoteServices?.services} />
                    </Modal.Body>
                </div>
            }
            {
                !finished &&
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="m-4">
                    <Modal.Header closeButton>
                        <Modal.Title>Appointment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <QuoteDetails discount={props.estimate.Discount} estimate={props.estimate} mainService={props.quoteServices?.mainService} services={props.quoteServices?.services} />
                        </Form.Row>
                        <Form.Row>
                            {/* First Name */}

                            <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    onChange={e => customer.firstName = e.target.value}
                                    defaultValue={props.estimate.Customer?.firstName ?? ""}
                                    type="text"
                                    placeholder="Enter Your First Name" />
                                <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                            </Form.Group>

                            {/* Last Name */}

                            <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter Your Last Name" onChange={e => customer.lastName = e.target.value} defaultValue={props.estimate.Customer?.lastName ?? ""} />
                                <Form.Control.Feedback type="invalid">Please enter your last name</Form.Control.Feedback>
                            </Form.Group>

                            {/* Street Address */}
                            <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formStreetAddress">
                                <Form.Label>Address</Form.Label>
                                <Form.Control required type="text" placeholder="Enter Your Address" onChange={e => customer.address = e.target.value} defaultValue={props.estimate.Customer?.address ?? ""} />
                                <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                            </Form.Group>


                            {/* Phone Number */}
                            <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formPhoneNumber">
                                <Form.Label>Cell Phone Number</Form.Label>
                                <Form.Control required type="number" placeholder="Enter Your Cell Phone Number" onChange={e => customer.phoneNumber = e.target.value} defaultValue={props.estimate.Customer?.phoneNumber ?? ""} />
                                <Form.Control.Feedback type="invalid">Please enter your cell phone number</Form.Control.Feedback>
                            </Form.Group>


                            {/* Zip Code */}
                            <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formZipCode">
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control required type="number" placeholder="Enter Your Zip Code" onChange={e => customer.zipCode = parseInt(e.target.value)} defaultValue={props.estimate.Customer?.zipCode ?? ""} />
                                <Form.Control.Feedback type="invalid">Please enter your zip code</Form.Control.Feedback>
                            </Form.Group>

                            {/* Email Address */}
                            <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control required type="email" placeholder="Enter Your Email Address" onChange={e => customer.email = e.target.value} defaultValue={props.estimate.Customer?.email ?? ""} />
                                <Form.Control.Feedback type="invalid">Please enter your email address</Form.Control.Feedback>
                            </Form.Group>

                            {/* Area */}
                            <Form.Group as={Col} md="6" xs="12">
                                <Form.Label>Area</Form.Label>
                                <Form.Control as="select" size="lg" onChange={e => customer.area = e.target.value} defaultValue={props.estimate.Customer?.area ?? ""} custom>
                                    <option>North Las Vegas</option>
                                    <option>Henderson</option>
                                    <option>Lake Las Vegas</option>
                                    <option>Summerlin</option>
                                    <option>Boulder City</option>
                                    <option>Aliante</option>
                                    <option>Other</option>
                                </Form.Control>
                            </Form.Group>

                            {/* Email */}
                            <Form.Group as={Col} md="6" xs="12" controlId="formContactEmail">
                                <Form.Label>Is it okay if we contact you through email?</Form.Label>
                                <Form.Control
                                    onChange={e => misc.canEmail = e.target.value == CONSTANTS.YES ? true : false}
                                    defaultValue={props.estimate.Misc?.canEmail == true ? CONSTANTS.YES : CONSTANTS.NO}
                                    as="select" size="lg" custom>
                                    <option>Yes</option>
                                    <option>No</option>
                                </Form.Control>
                            </Form.Group>

                            {/* Phone Number  */}
                            <Form.Group as={Col} md="6" xs="12" controlId="formContactPhoneNumber">
                                <Form.Label>Is it okay if we contact you through text or call you?</Form.Label>
                                <Form.Control
                                    onChange={e => misc.canText = e.target.value == CONSTANTS.YES ? true : false}
                                    defaultValue={props.estimate.Misc?.canText == true ? CONSTANTS.YES : CONSTANTS.NO}
                                    as="select" size="lg" custom>
                                    <option>Yes</option>
                                    <option>No</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="mr-4">Page 4 of 4</span>
                        <Button variant="secondary" onClick={props.previousPage}>Go Back</Button>
                        <div>
                            {
                                processing && <Spinner animation="border" role="status" />
                            }
                            {
                                !processing &&
                                <Button variant="primary" type="submit">
                                    Book Appointment
                                </Button>
                            }
                        </div>
                        <div>
                            {
                                error &&
                                <Alert variant="danger">{error}</Alert>
                            }
                        </div>
                    </Modal.Footer>
                </Form>

            }
        </div >
    )

}

export default PersonalInformationForm