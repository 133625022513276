import React from 'react'
import { useState } from 'react'
import PersonalInformationForm from './personalInfo'
import GeneralInformationForm from './generalInformation'
import PaneCount from './paneCount'
import ScreenCount from './screenCount'
import EndEstimator from './endEstimator'
import { Estimate } from './models/estimate'
import { Quote, SelectedService } from './quote'
import CONSTANTS from './constants/constants'
import { PressureWashingGeneralInfo } from './pressureWashing'

export type QuoteServices = {
    mainService: SelectedService,
    services: SelectedService[]
}

const Estimator = () => {
    type Service = "window_cleaning" | "pressure_washing"

    const [serviceType, setServiceType] = useState<Service>("window_cleaning")

    const GENERAL_INFORMATION = 1, PANE_COUNT = 2, SCREEN_COUNT = 2, END_ESTIMATOR = 2, QUOTE = 3, PERSONAL_INFORMATION = 4

    const PRESSURE_WASHING_GENERAL_INFORMATION = 1

    const [page, setPage] = useState(GENERAL_INFORMATION)

    const [quoteServices, setQuoteServices] = useState<QuoteServices>()

    const [estimate, setEstimate] = useState<Estimate>({
        Discount: 0.10,
        Misc: {
            canEmail: true,
            canText: true
        },
        Customer: {
            area: CONSTANTS.SUMMERLIN
        },
        GeneralInformation: {
            howHearAboutUs: CONSTANTS.HOWFOUNDUS.FACEBOOK,
            floorsAboveGround: CONSTANTS.FLOORS.ONE_STORY,
            basement: false,
            houseSize: CONSTANTS.HOUSESIZE.SMALLEST,
            residential: true
        }
    })

    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const nextPage = () => {
        if (page < PERSONAL_INFORMATION) {
            setPage(page + 1)
        }
    }

    return (
        <div>

            {
                serviceType === "window_cleaning" &&
                <div>
                    {page == GENERAL_INFORMATION && <GeneralInformationForm estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
                    {/* {page == PANE_COUNT && <PaneCount estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />} */}
                    {/* {page == SCREEN_COUNT && <ScreenCount estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />} */}
                    {page == END_ESTIMATOR && <EndEstimator estimate={estimate} setEstimate={setEstimate} nextPage={nextPage} previousPage={previousPage} />}
                    {page == QUOTE && <Quote estimate={estimate} previousPage={previousPage} setEstimate={setEstimate} nextPage={nextPage} setQuoteServices={setQuoteServices} />}
                    {page == PERSONAL_INFORMATION && <PersonalInformationForm estimate={estimate} setEstimate={setEstimate} previousPage={previousPage} quoteServices={quoteServices} />}
                </div>
            }
            {
                serviceType === "pressure_washing" &&
                <div>
                    <PressureWashingGeneralInfo estimate={estimate} />
                </div>
            }
        </div >
    )

}

export default Estimator