import React, { useEffect } from 'react';
import './App.css';
import Estimator from './components/estimator/estimator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Image, Modal, ModalBody, Row } from 'react-bootstrap';
import firebase from 'firebase';
import { hotjar } from 'react-hotjar'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {

  useEffect(() => {

    hotjar.initialize(2197434, 0)

    if (firebase.apps.length == 0) {
      // Initialize Cloud Firestore through Firebase
      firebase.initializeApp({
        apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
        authDomain: 'dephyned-web.firebaseapp.com',
        projectId: 'dephyned-web'
      });
    }
  }, [])

  return (

    <div className="App">
      <Modal size='xl' show={true}>
        <ModalBody>
          <Container>
            <Row className="my-3">
              <Col xs="3" md="2" className="mt-2 ml-2">
                <a href="https://definedcleaning.com" target="_blank">
                  <Image src="/images/logo.png" height={100} width={170} />
                </a>
              </Col>
              <Col className="ml-3">
                <div>
                  <h3 style={{ fontWeight: "bold" }}>Defined Home Services, LLC</h3>
                </div>
                <div>
                  <strong>(702) 747-0901</strong>
                </div>
                <div>
                  <a href="https://definedcleaning.com" target="_blank"><strong>definedcleaning.com</strong></a>
                </div>
                <div>
                  All 5-star reviews on Google, Yelp, Thumbtack, and Facebook
                </div>
              </Col>
            </Row>
          </Container>
          {/* <Estimator /> */}
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Estimator />} />
            </Routes>
          </BrowserRouter>
        </ModalBody>
      </Modal>
    </div >
  );
}

export default App;
